import React from 'react'
import { useHistory } from 'react-router-dom'
import { Action, Claim } from '@straetus/constants/auth'
import { buildAbility, createCaslAbilityBuilder } from '@straetus/node/auth'
import { useErrorReporter } from '@straetus/react/components/error-boundary'
import FullScreenLoader from '@straetus/react/components/full-screen-loader'
import { CompanyType, DossierStatus } from '@straetus/react/interfaces'
import { useAuthApi } from '@straetus/react/modules/auth'
import { AbilityContext } from '@straetus/react/modules/auth'
import { useFocusQuery } from '@straetus/react/modules/graphql'
import { ActiveCompanyContext, BETA_FEATURES_STORAGE_KEY, UserContext } from '@straetus/react/modules/user'
import { usePostHog } from 'posthog-js/react'

import type { AbilityBuilder, PureAbility } from '@casl/ability'
import type { Dossier, Invoice, Note, Query, Workflow } from '@straetus/react/interfaces'

import { MeQuery } from './user.graphql'
import { getCurrentCompanyFromUrl, getCurrentUrlWithoutCompany, isNotRedirectableUrl } from './user.utils'

export default function UserProvider({ children }: React.PropsWithChildren) {
  const [userLoading, setUserLoading] = React.useState(true)
  const errorReporting = useErrorReporter()
  const posthog = usePostHog()
  const history = useHistory()

  const [selectCompany] = useAuthApi('companyToken')

  const identifiedCompanySlug = React.useRef('')

  const meQuery = useFocusQuery<Pick<Query, 'me' | 'activeCompany'>>(MeQuery, {
    fetchPolicy: 'cache-and-network',
    onCompleted: async ({ me: { id }, activeCompany }) => {
      // Set the Google Analytics id
      errorReporting.setUser(id)
      posthog?.identify(id, {
        beta_mode: localStorage.getItem(BETA_FEATURES_STORAGE_KEY) ? 'enabled' : 'disabled'
      })

      const currentCompany = getCurrentCompanyFromUrl()
      if (currentCompany === 'ac') {
        if (activeCompany) {
          // Go to the correct active company
          // replace the state
          history.replace(`/${activeCompany.slug}${getCurrentUrlWithoutCompany()}`)

          // Replacing the state to active company, no longer loading
          // as active company was correct
          setUserLoading(false)
        } else {
          // Go select a company
          history.replace('/select')
        }
      } else if (isNotRedirectableUrl(`/${currentCompany}`)) {
        // Do nothing

      } else if (!activeCompany || activeCompany.slug !== currentCompany) {
        // Set loading back to true, this will prevent
        setUserLoading(true)

        // Select the correct company
        const { error } = await selectCompany(currentCompany as string)

        if (error) {
          // We can ignore some errors, page itself will handle it with the correct redirect
          if (!error.code || !['api.no-access'].includes(error.code)) {
            history.push('/select')
          }
        } else {
          // Refetch the active user
          await meQuery.refetch()
        }
      } else if (activeCompany.slug !== identifiedCompanySlug.current) {
        posthog?.group('company', activeCompany.id, {
          name: activeCompany.name,
          type: activeCompany.type,
          env: activeCompany.env,
          country: activeCompany.country
        })

        // Prevents sending events when focus is back
        identifiedCompanySlug.current = activeCompany.slug
      }

      // Set loading to false for all others than "/ac" cases
      if (activeCompany.slug === currentCompany) {
        // We are no longer loading, prevents access leaking from one company to the other
        setUserLoading(false)
      }
    }
  })

  const userAbilities = React.useMemo(() => {
    const abilityBuilder = createCaslAbilityBuilder(
      meQuery.data?.me?.id,
      meQuery.data?.me?.type,
      meQuery.data?.activeCompany?.type,
      meQuery.data?.me?.scopes || []
    ) as AbilityBuilder<PureAbility<[Action, Dossier | Note | Workflow | Claim]>>

    if (meQuery.data?.activeCompany?.type !== CompanyType.International) {
      // Make sure uses can only update their own workflows
      abilityBuilder.cannot<Workflow>(Action.Update, Claim.Workflow, { companyId: { $ne: meQuery.data?.activeCompany?.id } })
    }

    // Make sure the user can only update open / draft dossiers
    abilityBuilder.cannot<Dossier>(Action.Update, Claim.Dossier, {
      status: { $in: [DossierStatus.Paid, DossierStatus.Paused, DossierStatus.AutoResume, DossierStatus.Closed] }
    })

    // Make sure the user can only delete draft dossiers
    abilityBuilder.cannot<Dossier>(Action.Delete, Claim.Dossier, {
      status: { $nin: [DossierStatus.Draft, DossierStatus.AutoOpen] }
    })

    // Make sure the user can only update manual debtor management dossiers
    abilityBuilder.cannot<Dossier>(Action.Update, Claim.Dossier, {
      manager: { $ne: null }
    })

    if (meQuery.data?.activeCompany?.type !== CompanyType.International) {
      // Make sure the user can only update their own invoices
      abilityBuilder.cannot<Invoice>(Action.Update, Claim.Invoice, {
        debtorId: { $eq: meQuery.data?.activeCompany?.id }
      })
    }

    return buildAbility(abilityBuilder as never)
  }, [meQuery.data?.me, meQuery.data?.activeCompany])

  if (!meQuery.data || !meQuery.data.activeCompany || userLoading) {
    // Only show the delay if the active company/me is still being fetched
    return <FullScreenLoader withDelay={!meQuery.data || !meQuery.data.activeCompany} />
  }

  return (
    <UserContext.Provider value={meQuery.data.me}>
      <ActiveCompanyContext.Provider value={meQuery.data.activeCompany}>
        <AbilityContext.Provider value={userAbilities}>
          {children}
        </AbilityContext.Provider>
      </ActiveCompanyContext.Provider>
    </UserContext.Provider>
  )
}
